import { Injectable, inject } from '@angular/core';
import { WatchQueryOptions } from 'apollo-client';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { allowMonLogTypeDelete } from '../graphql/monitoring-logs/allow-mon-log-type-delete';
import { createMonLog } from '../graphql/monitoring-logs/create-mon-log';
import { createMonLogType } from '../graphql/monitoring-logs/create-mon-log-type';
import { deleteMonLog } from '../graphql/monitoring-logs/delete-mon-log';
import { deleteMonLogType } from '../graphql/monitoring-logs/delete-mon-log-type';
import { emailMonLog } from '../graphql/monitoring-logs/email-mon-log';
import { getAvailableCommFailSeverities } from '../graphql/monitoring-logs/get-available-comm-fail-severities';
import { getAvailableGasReleaseTypeGrades } from '../graphql/monitoring-logs/get-available-gas-release-type-grades';
import { getAvailableMonitoringLogWorkTypes } from '../graphql/monitoring-logs/get-available-mon-log-work-types';
import { getAvailableMonitoringLogTypes } from '../graphql/monitoring-logs/get-available-monitoring-types';
import { getMonLogTypeHistory } from '../graphql/monitoring-logs/get-mon-log-type-history';
import { getMonitoringLogWithAlarms } from '../graphql/monitoring-logs/get-monitoring-log-with-alarms';
import { getActiveMonLogs } from '../graphql/monitoring-logs/get-active-monitoring-logs';
import { queryForMonitoringLog } from '../graphql/monitoring-logs/query-for-all-mon-logs';
import { queryMonLogTypes } from '../graphql/monitoring-logs/query-mon-log-types';
import { updateMonLog } from '../graphql/monitoring-logs/update-mon-log';
import { updateMonLogType } from '../graphql/monitoring-logs/update-mon-log-type';
import { MonLogTypeInput } from '../model/admin/mon-log-type-input';
import { EmailRequest } from '../model/admin/email-request';
import { AppsyncService } from './appsync.service';
import { AuthApiService } from './auth-api.service';
import { AuthService } from './auth.service';
import { BaseAppSyncService } from './base-app-sync.service';
import { TC_CLAIMS_HEADER } from '../constants/auth-constants';
import { FacilityContactsForMonLog } from '../model/monlogs/facility-contacts-for-mon-log';
import { getFacilityContactsForMonLog } from '../graphql/monitoring-logs/get-facility-contacts-for-mon-log';
import { MonlogsBuilderService } from './monlogs-builder.service';
import { createMonLogEntry } from '../graphql/monitoring-logs/create-mon-log-entry';
import {
  MonLogEntry,
  UpdateMonLogEntryInput,
} from '../model/monlogs/mon-log-entry';
import { updateMonLogEntry } from '../graphql/monitoring-logs/update-mon-log-entry';
import { deleteMonLogEntry } from '../graphql/monitoring-logs/delete-mon-log-entry';
import { createMonLogAlarm } from '../graphql/monitoring-logs/create-mon-log-alarm';
import {
  MonLogAlarm,
  UpdateMonLogAlarmInput,
} from '../model/monlogs/mon-log-alarm';
import { updateMonLogAlarm } from '../graphql/monitoring-logs/update-mon-log-alarm';
import { deleteMonLogAlarm } from '../graphql/monitoring-logs/delete-mon-log-alarm';
import {
  ControlRoomEventReviewEntry,
  ControlRoomEventReviewEntryInput,
} from '../model/monlogs/control-room-event-review-entry';
import { createCREREntry } from '../graphql/monitoring-logs/create-crer-entry';
import { updateCREREntry } from '../graphql/monitoring-logs/update-crer-entry';
import { deleteCREREntry } from '../graphql/monitoring-logs/delete-crer-entry';

@Injectable({
  providedIn: 'root',
})
export class MonlogsApiService extends BaseAppSyncService {
  protected _api: AppsyncService;
  protected _authApi = inject(AuthApiService);
  private _auth = inject(AuthService);
  private monLogBuilder = inject(MonlogsBuilderService);

  constructor() {
    const _api = inject(AppsyncService);

    super(_api);
    this._api = _api;

  }

  loadAvailableLogTypes(): Observable<any> {
    // Grab all Monitoring Log types
    // Set to arbitrarily large value to avoid pagination / filtering
    // Default sort is on name, descending
    // need to pass null for business units so the backend function will filter based on user roles
    const variables = {
      limit: 9999999,
      page: 1,
      query: {
        name: '',
        enabled: true,
        businessUnits: null,
        requiresCRER: null,
      },
      sort: {
        name: 'DESC',
      },
    };

    const options: WatchQueryOptions = {
      query: getAvailableMonitoringLogTypes,
      variables,
      context: {
        headers: {
          [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
        },
      },
      fetchPolicy: 'cache-first',
    };

    return from(this._api.hc2()).pipe(
      switchMap((client) => {
        return client.query(options);
      })
    );
  }

  loadAvailableGasReleaseTypeGrades(): Observable<any> {
    const options: WatchQueryOptions = {
      context: {
        headers: {
          [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
        },
      },
      query: getAvailableGasReleaseTypeGrades,
      fetchPolicy: 'cache-first',
    };

    return from(this._api.hc2()).pipe(
      switchMap((client) => {
        return client.query(options);
      })
    );
  }

  loadAvailableCommFailSeverities(): Observable<any> {
    const options: WatchQueryOptions = {
      context: {
        headers: {
          [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
        },
      },
      query: getAvailableCommFailSeverities,
      fetchPolicy: 'cache-first',
    };

    return from(this._api.hc2()).pipe(
      switchMap((client) => {
        return client.query(options);
      })
    );
  }

  loadAvailableMonLogWorkTypes(): Observable<any> {
    const variables = {
      limit: 9999999,
      page: 1,
      query: {
        name: '',
        enabled: true,
      },
      sort: {
        name: 'DESC',
      },
    };

    const options: WatchQueryOptions = {
      query: getAvailableMonitoringLogWorkTypes,
      variables,
      context: {
        headers: {
          [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
        },
      },
      fetchPolicy: 'cache-first',
    };

    return from(this._api.hc2()).pipe(
      switchMap((client) => {
        return client.query(options);
      })
    );
  }

  queryForMonitoringLogs(
    limit: number,
    page: number,
    query: any,
    sort: any
  ): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: queryForMonitoringLog,
          fetchPolicy: 'no-cache',
          variables: {
            limit,
            page,
            query,
            sort,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  getActiveMonLogs(
    limit: number,
    page: number,
    query: any,
    sort: any,
    filter: string[]
  ): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getActiveMonLogs,
          fetchPolicy: 'no-cache',
          variables: {
            limit,
            page,
            query,
            sort,
            filter,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  createMonLog(logInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: createMonLog,
          variables: { input: logInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  updateMonLog(log, logInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: updateMonLog,
          variables: { id: log.id, input: logInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  deleteMonitoringLog(id): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: deleteMonLog,
          variables: {
            id: id,
            updater: this._auth.getUserName(),
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  emailMonLog(request: EmailRequest): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: emailMonLog,
          variables: {
            input: request,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  getMonitoringLogWithAlarms(id): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getMonitoringLogWithAlarms,
          fetchPolicy: 'no-cache',
          variables: {
            id,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  queryMonLogTypes(
    limit: number,
    page: number,
    query: any,
    sort: any
  ): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: queryMonLogTypes,
          fetchPolicy: 'no-cache',
          variables: {
            limit,
            page,
            query,
            sort,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  createMonLogType(input: MonLogTypeInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: createMonLogType,
          variables: { input: input },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  updateMonLogType(id: string, changes: MonLogTypeInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: updateMonLogType,
          variables: { id: id, changes: changes },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  deleteMonLogType(id): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: deleteMonLogType,
          variables: { id },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  getMonLogTypeHistory(id: string): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getMonLogTypeHistory,
          variables: {
            id: id,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          fetchPolicy: 'no-cache',
        };
        return client.query(options);
      })
    );
  }

  allowMonLogTypeDelete(id: string): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: allowMonLogTypeDelete,
          variables: {
            id: id,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          fetchPolicy: 'no-cache',
        };
        return client.query(options);
      })
    );
  }

  getFacilityContactsForMonLog(
    facilityId: string
  ): Observable<FacilityContactsForMonLog> {
    return this.query(
      getFacilityContactsForMonLog,
      { facilityId },
      (i) => this.monLogBuilder.buildFacilityContactsForMonLog(i),
      'getFacilityContactsForMonLog'
    );
  }

  createMonLogEntry(
    monLogId: string,
    input: UpdateMonLogEntryInput
  ): Observable<MonLogEntry> {
    return this.mutate(
      createMonLogEntry,
      { monLogId, input },
      (i) => this.monLogBuilder.buildMonLogEntry(i),
      'createMonLogEntry'
    );
  }

  updateMonLogEntry(
    id: string,
    input: UpdateMonLogEntryInput
  ): Observable<MonLogEntry> {
    return this.mutate(
      updateMonLogEntry,
      { id, input },
      (i) => this.monLogBuilder.buildMonLogEntry(i),
      'updateMonLogEntry'
    );
  }

  deleteMonLogEntry(id: string): Observable<boolean> {
    return this.mutate(
      deleteMonLogEntry,
      { id },
      (i) => i.value,
      'deleteMonLogEntry'
    );
  }

  createMonLogAlarm(
    monLogId: string,
    input: UpdateMonLogAlarmInput
  ): Observable<MonLogAlarm> {
    return this.mutate(
      createMonLogAlarm,
      { monLogId, input },
      (i) => this.monLogBuilder.buildMonLogAlarm(i),
      'createMonLogAlarm'
    );
  }

  updateMonLogAlarm(
    id: string,
    input: UpdateMonLogAlarmInput
  ): Observable<MonLogAlarm> {
    return this.mutate(
      updateMonLogAlarm,
      { id, input },
      (i) => this.monLogBuilder.buildMonLogAlarm(i),
      'updateMonLogAlarm'
    );
  }

  deleteMonLogAlarm(id: string): Observable<boolean> {
    return this.mutate(
      deleteMonLogAlarm,
      { id },
      (i) => i.value,
      'deleteMonLogAlarm'
    );
  }

  createCREREntry(
    monLogId: string,
    input: ControlRoomEventReviewEntryInput
  ): Observable<ControlRoomEventReviewEntry> {
    return this.mutate(
      createCREREntry,
      { monLogId, input },
      (i) => this.monLogBuilder.buildCREREntry(i),
      'createCREREntry'
    );
  }

  updateCREREntry(
    id: string,
    input: ControlRoomEventReviewEntryInput
  ): Observable<ControlRoomEventReviewEntry> {
    return this.mutate(
      updateCREREntry,
      { id, input },
      (i) => this.monLogBuilder.buildCREREntry(i),
      'updateCREREntry'
    );
  }

  deleteCREREntry(id: string): Observable<boolean> {
    return this.mutate(
      deleteCREREntry,
      { id },
      (i) => i.value,
      'deleteCREREntry'
    );
  }
}
