import { Injectable, inject } from '@angular/core';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { dynamicSort, onlyUnique } from '../core/functions/common-functions';
import { getLogIdFromDate } from '../core/functions/date-functions';
import { DirtyStatus } from '../model/common/dirty-status';
import { Contact } from '../model/contacts/contact';
import { ContactList } from '../model/contacts/contact-list';
import { Facility } from '../model/locations/facility';
import { MonLog } from '../model/monlogs/mon-log';
import { MonLogEntry } from '../model/monlogs/mon-log-entry';
import { MonLogAudit } from '../model/monlogs/mon-log-audit';
import { MonLogContact } from '../model/monlogs/mon-log-call-contact';
import { MonLogInternalContact } from '../model/monlogs/mon-log-internal-contact';
import { MonLogCallout } from '../model/monlogs/mon-log-callout';
import { MonLogAlarm } from '../model/monlogs/mon-log-alarm';
import { MonLogSearchResult } from '../model/monlogs/mon-log-search-result';
import { AuthService } from './auth.service';
import {
  getCorrectedDate,
  isNullOrUndefined,
  sortByProperty,
} from '../utils/utils';
import { FacilityBuilderService } from './facility-builder.service';
import { ContactBuilderService } from './contact-builder.service';
import { FacilityContactsForMonLog } from '../model/monlogs/facility-contacts-for-mon-log';
import {
  A_AND_E_MAINTENANCE,
  M_AND_R_MAINTENANCE,
} from '../constants/monlog-constants';
import { ControlRoomEventReviewEntry } from '../model/monlogs/control-room-event-review-entry';

@Injectable({
  providedIn: 'root',
})
export class MonlogsBuilderService {
  private _auth = inject(AuthService);
  private _facilityBuilder = inject(FacilityBuilderService);
  private _contactBuilder = inject(ContactBuilderService);

  buildMonLogValidationMessages() {
    return {
      messages: {
        statusSummary: {
          required: 'COMMON.VALIDATION.REQ_FIELD.REQ_FIELD',
        },
        calloutRequired: {
          required: 'COMMON.VALIDATION.REQ_FIELD.REQ_FIELD',
        },
        typeOfCalls: {
          required: 'COMMON.VALIDATION.REQ_FIELD.REQ_FIELD',
        },
        gasReleaseType: {
          required: 'COMMON.VALIDATION.REQ_FIELD.REQ_FIELD',
        },
        commFailSeverity: {
          required: 'COMMON.VALIDATION.REQ_FIELD.REQ_FIELD',
        },
      },
      params: {
        statusSummary: {
          required: {
            field: 'Status Summary',
          },
        },
        calloutRequired: {
          required: { field: 'Callout Required' },
        },
        typeOfCalls: {
          required: { field: 'Type of Call' },
        },
        gasReleaseType: {
          required: { field: 'Gas Release Type' },
        },
        commFailSeverity: {
          required: { field: 'Comm Fail Severity' },
        },
      },
    };
  }

  buildMonLogFromFacility(
    facility: Facility,
    contactList: ContactList,
    category = 'N'
  ): MonLog {
    const contacts = [];
    const members =
      contactList && contactList.members
        ? contactList.members.sort((a, b) => a.order - b.order)
        : [];
    const _this = this;
    for (let i = 0; i < members.length; i++) {
      const m = members[i];
      if (
        m.contact.isUnavailable === 'FALSE' &&
        m.contact.inactiveAdAccount !== true
      ) {
        contacts.push(_this.buildMonLogContactsFromContact(m.contact));
        break;
      }
    }

    const retVal: MonLog = {
      createdAt: null,
      deletedAt: null,
      createdBy: null,
      updatedAt: null,
      id: null,
      logCategory: category,
      MonLogEntries: [],
      MonLogAlarms: [],
      MonLogAudits: [],
      MonLogCallouts: [],
      MonLogNotifications: [],
      MonLogTypes: [],
      MonLogWorkTypes: [],
      businessUnit: facility.businessUnit,
      MonLogContacts: contacts,
      Facility: facility,
      pipeline: null,
      timeBegan: new Date(),
      calloutRequired: false,
      customerOutage: false,
      falseAlarm: false,
      followUpCall: false,
      backInService: false,
      internalOne: null,
      internalTwo: null,
      state: facility.locations[0]?.state,
      county: facility.locations[0]?.county,
      township: facility.locations[0]?.township,
      version: 0,
      crerCompletedAt: null,
    };

    return retVal;
  }

  buildNullMonLog(category = 'N'): MonLog {
    const retVal: MonLog = {
      createdAt: null,
      deletedAt: null,
      createdBy: null,
      updatedAt: null,
      crerCompletedAt: null,
      id: null,
      logCategory: category,
      MonLogEntries: [],
      MonLogAlarms: [],
      MonLogAudits: [],
      MonLogCallouts: [],
      MonLogNotifications: [],
      MonLogTypes: [],
      MonLogWorkTypes: [],
      MonLogContacts: [],
      Facility: null,
      pipeline: null,
      timeBegan: new Date(),
      calloutRequired: false,
      customerOutage: false,
      falseAlarm: false,
      followUpCall: null,
      backInService: false,
      internalOne: null,
      internalTwo: null,
      version: 0,
    };

    return retVal;
  }

  buildActiveMonLogQuery(filters) {
    const query = {
      facilityName: null,
      logId: null,
      callType: null,
      statusSummary: null,
      facilityUid: null,
      pipeline: null,
      primaryContact: null,
      createdAt: null,
      logStatus: null,
      businessUnits: null,
    };

    if (filters.facilityName) {
      query.facilityName = filters.facilityName.value.trim();
    }
    if (filters.logId) {
      query.logId = filters.logId.value.trim();
    }
    if (filters.callType) {
      query.callType = filters.callType.value.trim();
    }
    if (filters.statusSummary) {
      query.statusSummary = filters.statusSummary.value.trim();
    }
    if (filters.facilityUid) {
      query.facilityUid = filters.facilityUid.value;
    }

    if (filters.pipeline) {
      query.pipeline = filters.pipeline.value.trim();
    }
    if (filters.primaryContact) {
      query.primaryContact = filters.primaryContact.value.trim();
    }
    if (filters.createdAt) {
      query.createdAt = filters.createdAt.value;
    }
    if (filters.statusName) {
      query.logStatus = filters.statusName.value.trim();
    }
    if (filters.businessUnits) {
      query.businessUnits = filters.businessUnits;
    }

    return query;
  }

  buildMonLogQuery(filters) {
    const query = {
      facilityName: null,
      logId: null,
      callType: null,
      statusSummary: null,
      facilityUid: null,
      facilityType: null,
      state: null,
      county: null,
      township: null,
      pipeline: null,
      primaryContact: null,
      company: null,
      createdAt: null,
      logStatus: null,
      createdBy: null,
      businessUnits: null,
    };

    if (filters.facilityName) {
      query.facilityName = filters.facilityName.value.trim();
    }
    if (filters.logId) {
      query.logId = filters.logId.value.trim();
    }
    if (filters.callType) {
      query.callType = filters.callType.value.trim();
    }
    if (filters.statusSummary) {
      query.statusSummary = filters.statusSummary.value.trim();
    }
    if (filters.facilityUid) {
      query.facilityUid = filters.facilityUid.value;
    }

    if (filters.state) {
      query.state = filters.state.value.trim();
    }
    if (filters.county) {
      query.county = filters.county.value.trim();
    }
    if (filters.township) {
      query.township = filters.township.value.trim();
    }
    if (filters.facilityType) {
      query.facilityType = filters.facilityType.value.trim();
    }

    if (filters.pipeline) {
      query.pipeline = filters.pipeline.value.trim();
    }
    if (filters.primaryContact) {
      query.primaryContact = filters.primaryContact.value.trim();
    }
    if (filters.company) {
      query.company = filters.company.value.trim();
    }
    if (filters.createdAt) {
      query.createdAt = filters.createdAt.value;
    }
    if (filters.statusName) {
      query.logStatus = filters.statusName.value.trim();
    }
    if (filters.createdBy) {
      query.createdBy = filters.createdBy.value.trim();
    }
    if (filters.businessUnits) {
      query.businessUnits = filters.businessUnits;
    }

    return query;
  }

  buildMonLogSearch(item: any): MonLogSearchResult {
    return {
      id: item.id,
      facilityName: item.facilityName,
      facilityUid: item.pipeline ? item.pipeline : item.facilityUid,
      facilityType: item.facilityType,
      state: item.state,
      county: item.county,
      township: item.township,
      pipeline: item.pipeline,
      statusSummary: item.statusSummary,
      callType: item.callTypes,
      createdAt: getCorrectedDate(item.createdAt),
      logStatus: item.logStatus,
      logId: item.logId,
      statusName: this.getNameForStatus(item.logStatus),
      primaryContact:
        item.firstName && item.lastName
          ? item.firstName + ' ' + item.lastName
          : '',
      createdBy: item.createdBy,
    };
  }

  buildActiveMonLogForTable(item: any): object {
    const icons = [];

    if (
      (item.createdBy.toLowerCase() === 'system' &&
        (item.updater === null || item.updater.toLowerCase() === 'system')) ||
      item.updater?.toLowerCase() === 'system'
    ) {
      icons.push('fa fa-exclamation-triangle');
    }

    if (item.logStatus === 'A') {
      icons.push('fa fa-hourglass');
    }

    if (item.logStatus === 'C') {
      icons.push('fa fa-thumbs-o-up');
    }

    if (item.logStatus === 'R') {
      icons.push('fa fa-check');
    }
    const returnItem = {
      ...item,
      indicators: icons,
      statusName: this.getNameForStatus(item.logStatus),
      // If we have a pipeline we want to show that in the facility uid spot instead
      // because normally facility uids for these are PIPELINE FACILITIES or similar
      facilityUid: item.pipeline ? item.pipeline : item.facilityUid,
      actionText: this.determineActionText(item),
    };

    return returnItem;
  }

  determineActionText(item: any) {
    if (
      item.callType.toLowerCase() === A_AND_E_MAINTENANCE.toLowerCase() ||
      item.callType.toLowerCase() === M_AND_R_MAINTENANCE.toLowerCase()
    ) {
      return 'Resolve';
    }

    return 'Add';
  }

  buildMonLog(item: any): MonLog {
    let callType = '';
    if (item.MonLogTypes) {
      callType = item.MonLogTypes.map((t) => t.name)
        .filter(onlyUnique)
        .join(', ');
    }
    const icons = [];

    if (
      (item.createdBy.toLowerCase() === 'system' &&
        (item.updater === null || item.updater.toLowerCase()) === 'system') ||
      item.updater?.toLowerCase() === 'system'
    ) {
      icons.push('fa fa-exclamation-triangle');
    }

    if (item.logStatus === 'A') {
      icons.push('fa fa-hourglass');
    }

    if (item.logStatus === 'C') {
      icons.push('fa fa-thumbs-o-up');
    }

    if (item.logStatus === 'R') {
      icons.push('fa fa-check');
    }
    const callouts = this.buildMonLogCallouts(item.MonLogCallouts);
    const primary =
      item?.MonLogContacts && item?.MonLogContacts.length > 0
        ? item?.MonLogContacts[0]?.primaryFullName
        : null;
    const log: MonLog = {
      id: item.id,
      logId: item.logId,
      callType: callType,
      reviewRequestedDt: getCorrectedDate(item.reviewRequestedDt),
      reviewCompletedDt: getCorrectedDate(item.reviewCompletedDt),
      reviewer: item.reviewer,
      statusSummary: item.statusSummary,
      businessUnit: item.businessUnit,
      Facility: item.Facility
        ? this._facilityBuilder.buildFacility(item.Facility, [])
        : null,
      logStatus: item.logStatus,
      statusName: this.getNameForStatus(item.logStatus),
      logCategory: item.logCategory,
      calloutRequired: item.calloutRequired,
      oneCallNumber: item.oneCallNumber,
      commFailSeverity: item.CommFailSeverity
        ? { id: item.CommFailSeverity.id, name: item.CommFailSeverity.name }
        : null,
      customerOutage: item.customerOutage,
      falseAlarm: item.falseAlarm,
      followUpCall: item.followUpCall,
      backInService: item.backInService,
      gasReleaseType: item.GasReleaseTypeGrade
        ? {
            id: item.GasReleaseTypeGrade.id,
            name: item.GasReleaseTypeGrade.name,
          }
        : null,
      ticketNumber: item.ticketNumber,
      timeBegan: getCorrectedDate(item.timeBegan),
      timeOnSite: getCorrectedDate(item.timeOnSite),
      notam: item.notam,
      expireDt: getCorrectedDate(item.expireDt),
      firstName: item.firstName,
      lastName: item.lastName,
      street: item.street,
      phone: item.phone,
      state: item.state,
      county: item.county,
      township: item.township,
      city: item.city,
      internalOne: this.buildMonLogInternalContact(item.InternalOne),
      internalTwo: this.buildMonLogInternalContact(item.InternalTwo),
      MonLogAlarms: this.buildMonLogAlarms(item.MonLogAlarms),
      MonLogEntries: this.buildMonLogEntries(item.MonLogEntries),
      MonLogAudits: this.buildMonLogAudits(item.MonLogAudits),
      MonLogNotifications: this.buildMonLogAudits(item.MonLogNotifications),
      MonLogContacts: this.buildMonLogContacts(item.MonLogContacts, callouts),
      MonLogTypes: item.MonLogTypes
        ? item.MonLogTypes.map((type) => ({
            label: type.name,
            value: type.id,
            requiresCRER: type.requiresCRER,
          }))
        : [],
      MonLogWorkTypes: item.MonLogWorkTypes
        ? item.MonLogWorkTypes.map((type) => ({
            label: type.name,
            value: type.id,
          }))
        : [],
      MonLogCallouts: callouts,
      createdAt: getCorrectedDate(item.createdAt),
      updatedAt: getCorrectedDate(item.updatedAt),
      deletedAt: getCorrectedDate(item.deletedAt),
      createdBy: item.createdBy,
      version: item.version,
      primaryContact: primary,
      closedDt: getCorrectedDate(item.closedDt),
      indicators: icons,
      pipeline: item.pipeline,
      ControlRoomEventReviewEntries: item.ControlRoomEventReviewEntries
        ? item.ControlRoomEventReviewEntries.map((entry) =>
            this.buildCREREntry(entry)
          ).sort((a, b) => sortByProperty(a, b, 'updatedAt'))
        : [],
      crerCompletedAt: getCorrectedDate(item.crerCompletedAt),
    };

    return log;
  }

  getNameForStatus(status) {
    if (!status) {
      return 'N/A';
    } else if (status === 'N') {
      return 'NEW';
    } else if (status === 'R') {
      return 'RESOLVED';
    } else if (status === 'O') {
      return 'OPEN';
    } else if (status === 'A') {
      return 'AWAITING REVIEW';
    } else if (status === 'C') {
      return 'REVIEW COMPLETE';
    } else {
      return status;
    }
  }

  getStatusForName(name) {
    if (!name) {
      return 'N/A';
    } else if (name === 'NEW') {
      return 'N';
    } else if (name === 'RESOLVED') {
      return 'R';
    } else if (name === 'OPEN') {
      return 'O';
    } else if (name === 'AWAITING REVIEW') {
      return 'A';
    } else if (name === 'REVIEW COMPLETE') {
      return 'C';
    } else {
      return name;
    }
  }

  buildMonLogCallouts(item: any[]): MonLogCallout[] {
    const retVal = [];

    if (!item) {
      return retVal;
    }

    item.forEach((c) => {
      const callout: MonLogCallout = {
        id: c.id,
        startDt: getCorrectedDate(c.startDt),
        endDt: getCorrectedDate(c.endDt),
        firstName: c.Contact ? c.Contact.firstName : '',
        lastName: c.Contact ? c.Contact.lastName : '',
        ContactId: c.Contact ? c.Contact.id : '',
        dirtyStatus: DirtyStatus.UNMODIFIED,
      };
      retVal.push(callout);
    });

    return retVal.sort(dynamicSort('startDt', -1));
  }

  buildMonLogAudits(item: any[]): MonLogAudit[] {
    const retVal = [];

    if (!item) {
      return retVal;
    }

    item.forEach((e) => {
      const entry: MonLogAudit = {
        note: e.note,
        time: getCorrectedDate(e.time),
        user: e.user,
      };
      retVal.push(entry);
    });

    return retVal.sort(dynamicSort('time', -1));
  }

  buildMonLogEntries(item: any[]): MonLogEntry[] {
    const retVal = [];

    if (!item) {
      return retVal;
    }

    item.forEach((e) => {
      const entry: MonLogEntry = {
        id: e.id,
        note: e.note,
        time: getCorrectedDate(e.time),
        user: e.user,
        isResolution: e.isResolution,
      };
      retVal.push(entry);
    });

    return retVal.sort(dynamicSort('time', 1));
  }

  buildMonLogEntry(item: any): MonLogEntry {
    return {
      id: item.id,
      note: item.note,
      time: getCorrectedDate(item.time),
      user: item.user,
      isResolution: item.isResolution && item.isResolution === '1',
    };
  }

  buildMonLogAlarms(item: any[]): MonLogAlarm[] {
    const retVal = [];

    if (!item) {
      return retVal;
    }

    item.forEach((e) => {
      const entry: MonLogAlarm = {
        id: e.id,
        note: e.note,
        time: getCorrectedDate(e.time),
        user: e.user,
      };
      retVal.push(entry);
    });

    return retVal.sort(dynamicSort('time', 1));
  }

  buildMonLogAlarm(item: any): MonLogAlarm {
    return {
      id: item.id,
      note: item.note,
      time: getCorrectedDate(item.time),
      user: item.user,
    };
  }

  buildMonLogInternalContactFromContact(c: Contact): MonLogInternalContact {
    const retVal: MonLogInternalContact = {
      id: c.id,
      firstName: c.firstName,
      lastName: c.lastName,
      fullName: c.firstName + ' ' + c.lastName,
    };

    return retVal;
  }

  buildMonLogInternalContact(item: any): MonLogInternalContact {
    if (!item) {
      return null;
    }
    const retVal: MonLogInternalContact = {
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      fullName: item.firstName + ' ' + item.lastName,
    };

    return retVal;
  }

  buildMonLogContactsFromContact(c: Contact): MonLogContact {
    return {
      id: uuid(),
      primaryBusinessMobile: c.businessMobile,
      primaryFullName: c.firstName + ' ' + c.lastName,
      primaryFirstName: c.firstName,
      primaryLastName: c.lastName,
      primaryId: c.id,
      isPrimaryDispatched: false,
      supervisorBusinessMobile: c.supervisor
        ? c.raw.ReportsTo.businessMobile
        : null,
      supervisorFullName: c.supervisor,
      supervisorFirstName: c.supervisor ? c.supervisor.split(' ')[0] : null,
      supervisorLastName: c.supervisor ? c.supervisor.split(' ')[1] : null,
      supervisorId: c.reportsToId,
      isSupervisorDispatched: false,
      managerBusinessMobile: c.managerLevel
        ? c.raw.ReportsTo.ReportsTo.businessMobile
        : null,
      managerFullName: c.managerLevel,
      managerFirstName: c.managerLevel ? c.managerLevel.split(' ')[0] : null,
      managerLastName: c.managerLevel ? c.managerLevel.split(' ')[1] : null,
      managerId: c.managerId,
      isManagerDispatched: false,
    };
  }

  buildMonLogContacts(item: any, co: MonLogCallout[]): MonLogContact[] {
    const retVal = [];

    if (!item) {
      return retVal;
    }
    item.forEach((c) => {
      const contact: MonLogContact = {
        id: c.id,
        primaryId: c.Primary ? c.Primary.id : null,
        primaryFullName: c.Primary
          ? c.Primary.firstName + ' ' + c.Primary.lastName
          : null,
        primaryFirstName: c.Primary ? c.Primary.firstName : null,
        primaryLastName: c.Primary ? c.Primary.lastName : null,
        isPrimaryDispatched:
          co && co.length > 0 && c.Primary
            ? co.some((cl) => cl.ContactId === c.Primary.id && !cl.endDt)
            : false,
        primaryBusinessMobile: c.Primary ? c.Primary.businessMobile : null,
        supervisorId: c.Supervisor ? c.Supervisor.id : null,
        supervisorFullName: c.Supervisor
          ? c.Supervisor.firstName + ' ' + c.Supervisor.lastName
          : null,
        supervisorFirstName: c.Supervisor ? c.Supervisor.firstName : null,
        supervisorLastName: c.Supervisor ? c.Supervisor.lastName : null,
        isSupervisorDispatched:
          co && co.length > 0 && c.Supervisor
            ? co.some((cl) => cl.ContactId === c.Supervisor.id && !cl.endDt)
            : false,
        supervisorBusinessMobile: c.Supervisor
          ? c.Supervisor.businessMobile
          : null,
        managerId: c.Manager ? c.Manager.id : null,
        managerFullName: c.Manager
          ? c.Manager.firstName + ' ' + c.Manager.lastName
          : null,
        managerFirstName: c.Manager ? c.Manager.firstName : null,
        managerLastName: c.Manager ? c.Manager.lastName : null,
        isManagerDispatched:
          co && co.length > 0 && c.Manager
            ? co.some((cl) => cl.ContactId === c.Manager.id && !cl.endDt)
            : false,
        managerBusinessMobile: c.Manager ? c.Manager.businessMobile : null,
        directorFullName: c.Director
          ? c.Director.firstName + ' ' + c.Director.lastName
          : null,
        directorFirstName: c.Director ? c.Director.firstName : null,
        directorLastName: c.Director ? c.Director.lastName : null,
        createdAt: getCorrectedDate(c.createdAt),
      };
      retVal.push(contact);
    });
    retVal.sort(dynamicSort('createdAt', 1));
    return retVal;
  }

  buildNewMonLogAlarm(raw: any): MonLogAlarm {
    const retVal: MonLogAlarm = {
      id: uuid(),
      note: raw.statusUpdate,
      dirtyStatus: DirtyStatus.NEW,
      time: new Date(),
      user: this._auth.getUserName(),
    };

    return retVal;
  }

  buildNewMonLogEntry(raw: any): MonLogEntry {
    const retVal: MonLogEntry = {
      id: uuid(),
      note: raw.statusUpdate,
      dirtyStatus: DirtyStatus.NEW,
      time: raw.entryTime ? raw.entryTime : new Date(),
      user: this._auth.getUserName(),
      isResolution: raw.isResolution ? raw.isResolution : false,
    };

    return retVal;
  }

  buildMonLogCallCrudStatement(monLogInput: any, id = uuid(), version = 0) {
    const retVal = {
      id: id,
      expectedVersion: version,
    };

    return retVal;
  }

  buildMonLogCallCreate(formInput: any) {
    const retVal = {
      id: uuid(),
      version: 0,
      DirtyStatus: DirtyStatus.NEW,
    };

    if (formInput.customerOutage) {
      retVal['customerOutage'] = formInput.customerOutage;
    }
    if (formInput.calloutRequired) {
      retVal['calloutRequired'] = formInput.calloutRequired;
    }
    if (formInput.timeBegan) {
      retVal['timeBegan'] = formInput.timeBegan;
    }
    if (formInput.timeOnSite) {
      retVal['timeOnSite'] = formInput.timeOnSite;
    }
    if (formInput.ticketNumber) {
      retVal['ticketNumber'] = formInput.ticketNumber;
    }
    if (formInput.falseAlarm) {
      retVal['falseAlarm'] = formInput.falseAlarm;
    }
    if (formInput.gasReleaseType) {
      retVal['gasReleaseType'] = formInput.gasReleaseType;
    }
    if (formInput.commFailSeverity) {
      retVal['commFailSeverity'] = formInput.commFailSeverity;
    }
    if (formInput.notam) {
      retVal['notam'] = formInput.notam;
    }
    if (formInput.expireDt) {
      retVal['expireDt'] = formInput.expireDt;
    }

    return retVal;
  }

  buildMonLogContactsUpdate(contacts: MonLogContact[], changes: any[]) {
    const retVal = [];
    if (changes.length > 0) {
      changes.forEach((change) => {
        if (change.primary) {
          const arr = contacts.filter((c) => c.id === change.id);
          let item = {};
          if (arr.length > 0) {
            item = {
              id: arr[0].id,
              dirtyStatus: DirtyStatus.UPDATED,
            };
          } else {
            item = {
              id: uuid(),
              dirtyStatus: DirtyStatus.NEW,
            };
          }

          const obj = change.primary;
          Object.keys(obj).forEach((key) => {
            if (!isNullOrUndefined(obj[key])) {
              if (
                key === 'primaryId' ||
                key === 'supervisorId' ||
                key === 'managerId'
              ) {
                item[key] = obj[key];
              }
            }
          });

          retVal.push(item);
        }
      });
    }

    const needToRemove = contacts.some(
      (mc) => mc.dirtyStatus === DirtyStatus.DELETED
    );
    if (needToRemove) {
      contacts
        .filter((mc) => mc.dirtyStatus === DirtyStatus.DELETED)
        .forEach((mc) => {
          retVal.push({
            id: mc.id,
            dirtyStatus: DirtyStatus.DELETED,
          });
        });
    }

    return retVal;
  }

  buildMonLogWorkTypeUpdate(types: any[], inputTypes: any[], isNew = false) {
    const existingIds = types ? types.map((t) => t.value) : [];
    const newer = inputTypes
      ? inputTypes.filter((it) => existingIds.indexOf(it) < 0)
      : [];
    const deleted = inputTypes
      ? existingIds.filter((t) => inputTypes.indexOf(t) < 0)
      : [];
    const updated = inputTypes
      ? inputTypes.filter((it) => existingIds.indexOf(it) > 0)
      : [];
    let retVal = newer.map((t) => ({
      id: t,
      dirtyStatus: DirtyStatus.NEW,
    }));

    retVal = [
      ...retVal,
      ...updated.map((t) => ({
        id: t,
        dirtyStatus: DirtyStatus.UPDATED,
      })),
    ];

    retVal = [
      ...retVal,
      ...deleted.map((t) => ({
        id: t,
        dirtyStatus: DirtyStatus.DELETED,
      })),
    ];

    if (isNew) {
      retVal = [
        ...retVal,
        ...existingIds.map((t) => ({
          id: t,
          dirtyStatus: DirtyStatus.NEW,
        })),
      ];
    }
    return retVal;
  }

  buildMonLogTypeUpdate(types: any[], inputTypes: any[], isNew = false) {
    const existingIds = types ? types.map((t) => t.value) : [];
    const newer = inputTypes
      ? inputTypes.filter((it) => existingIds.indexOf(it.id) < 0)
      : [];
    const deleted = inputTypes
      ? existingIds.filter((t) => inputTypes.map((it) => it.id).indexOf(t) < 0)
      : [];
    const updated = inputTypes
      ? inputTypes.filter((it) => existingIds.indexOf(it.id) > 0)
      : [];
    let retVal = newer.map((t) => ({
      id: t.id,
      name: t.name,
      dirtyStatus: DirtyStatus.NEW,
    }));

    retVal = [
      ...retVal,
      ...updated.map((t) => ({
        id: t.id,
        name: t.name,
        dirtyStatus: DirtyStatus.UPDATED,
      })),
    ];

    retVal = [
      ...retVal,
      ...deleted.map((t) => ({
        id: t,
        name: null,
        dirtyStatus: DirtyStatus.DELETED,
      })),
    ];

    if (isNew) {
      retVal = [
        ...retVal,
        ...existingIds.map((t) => ({
          id: t,
          name: t.name,
          dirtyStatus: DirtyStatus.NEW,
        })),
      ];
    }
    return retVal;
  }

  buildUpdateStatement(monLog: MonLog, changes: any) {
    const retVal = {
      id: monLog.id ? monLog.id : uuid(),
      dirtyStatus: monLog.id ? DirtyStatus.UPDATED : DirtyStatus.NEW,
      expectedVersion: monLog.id ? monLog.version : 0,
      logStatus: monLog.id ? this.getField('logStatus', monLog, changes) : 'O',
      logCategory: monLog.logCategory
        ? this.getField('logCategory', monLog, changes)
        : 'N',
      logId: monLog.id ? monLog.logId : getLogIdFromDate(),
    };

    Object.keys(changes).forEach((key) => {
      if (
        key !== 'MonLogCallEntries' &&
        key !== 'MonLogCallActions' &&
        key !== 'MonLogCallouts' &&
        key !== 'Facility' &&
        key !== 'MonLogTypes' &&
        key !== 'MonLogContacts' &&
        key !== 'typeOfCalls' &&
        key !== 'workTypes'
      ) {
        if (!isNullOrUndefined(changes[key])) {
          retVal[key] = changes[key];
        }
      }
    });

    if (changes.Facility?.businessUnit) {
      retVal['businessUnit'] = changes.Facility.businessUnit.id;
    }

    if (changes.county) {
      retVal['county'] = changes['county'].county;
    }

    if (changes.township) {
      retVal['township'] = changes['township'].township;
    }

    if (changes.internalOne) {
      retVal['internalOne'] = changes.internalOne?.id;
    }

    if (changes.internalTwo) {
      retVal['internalTwo'] = changes.internalTwo?.id;
    }

    if (monLog.MonLogAlarms.some((e) => e.dirtyStatus > 0)) {
      retVal['MonLogAlarms'] = monLog.MonLogAlarms.filter(
        (e) => e.dirtyStatus > 0
      );
    }

    if (
      changes.MonLogCallouts ||
      monLog.MonLogCallouts.some((e) => e.dirtyStatus > 0)
    ) {
      retVal['MonLogCallouts'] = monLog.MonLogCallouts.filter(
        (e) => e.dirtyStatus > 0
      ).map((co) => ({
        ContactId: co.ContactId,
        id: co.id,
        startDt: co.startDt,
        endDt: co.endDt,
        dirtyStatus: co.dirtyStatus,
      }));
    }

    if (
      changes.typeOfCalls ||
      (monLog.MonLogTypes.length > 0 && retVal.dirtyStatus === DirtyStatus.NEW)
    ) {
      retVal['MonLogTypes'] = this.buildMonLogTypeUpdate(
        monLog.MonLogTypes,
        changes.typeOfCalls,
        retVal.dirtyStatus === DirtyStatus.NEW
      );
    }

    if (
      changes.workTypes ||
      (monLog.MonLogWorkTypes.length > 0 &&
        retVal.dirtyStatus === DirtyStatus.NEW)
    ) {
      retVal['MonLogWorkTypes'] = this.buildMonLogWorkTypeUpdate(
        monLog.MonLogWorkTypes,
        changes.workTypes,
        retVal.dirtyStatus === DirtyStatus.NEW
      );
    }

    if (
      (changes && changes.MonLogContacts) ||
      (monLog.MonLogContacts &&
        monLog.MonLogContacts.some(
          (mc) => mc.dirtyStatus === DirtyStatus.DELETED
        ))
    ) {
      const changeArray =
        changes && changes.MonLogContacts ? changes.MonLogContacts : [];
      retVal['MonLogContacts'] = this.buildMonLogContactsUpdate(
        monLog.MonLogContacts,
        changeArray
      );
    }

    if (changes.Facility?.facilityId) {
      retVal['facilityId'] = changes.Facility.facilityId;
    }
    if (changes.Facility?.pipeline) {
      retVal['pipeline'] = changes.Facility.pipeline;
    }

    return retVal;
  }

  getField(field: string, uiObj: any, input: any) {
    if (_.has(input, field) && input[field] !== undefined) {
      return input[field];
    } else if (_.has(uiObj, field) && uiObj[field] !== undefined) {
      return uiObj[field];
    } else {
      return null;
    }
  }

  buildMonLogTypes(types: any[]): any[] {
    const retVal: any[] = [];
    if (!isNullOrUndefined(types)) {
      types.forEach((type) => {
        retVal.push({ id: type.id, name: type.name, enabled: type.enabled });
      });
    }
    return retVal;
  }

  buildFacilityContactsForMonLog(item: any): FacilityContactsForMonLog {
    return {
      PrimaryTechnician: this._contactBuilder.buildContact(
        item?.PrimaryTechnician
      ),
      FirstOnCall: this._contactBuilder.buildContact(item?.FirstOnCall),
    };
  }

  buildCREREntry(item: any): ControlRoomEventReviewEntry {
    return {
      id: item.id,
      monLogId: item.monLogId,
      note: item.note,
      controlRoomInvolved: item.controlRoomInvolved,
      createdAt: getCorrectedDate(item.createdAt),
      createdBy: item.createdBy,
      updatedAt: getCorrectedDate(item.updatedAt),
      updater: item.updater,
      deletedAt: getCorrectedDate(item.deletedAt),
      version: item.version,
    };
  }
}
